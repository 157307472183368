import type { NewsLinkFragment, PageLinkFragment } from '#gql/default';

export type CustomLinkDefinition = {
  __typename: string;
  _allTranslatedSlugLocales: { value: string; locale: string }[];
};

export function useInternalRoute(
  internalUrl: PageLinkFragment | NewsLinkFragment | CustomLinkDefinition | null | undefined | {},
  locale: 'de' | 'en',
) {
  const path = computed(function () {
    if (!internalUrl || !('__typename' in internalUrl)) {
      return null;
    }

    let linkBase = '/';

    linkBase += `${locale}/`;

    if (internalUrl.__typename === 'NewsRecord') {
      linkBase += 'news/';
    } else if (internalUrl.__typename === 'PageRecord') {
      const pageLink = internalUrl as PageLinkFragment;
      if (pageLink.parent) {
        // NOTE: this allows only one level of nesting
        linkBase += `${pageLink.parent._allTranslatedSlugLocales?.find((it) => it.locale === locale)?.value}/`;
      }
    }

    const translatedSlug = internalUrl._allTranslatedSlugLocales?.find((it) => it.locale === locale)?.value ?? null;

    if (!translatedSlug) {
      return null;
    }

    if (translatedSlug === 'home') {
      return linkBase;
    }

    return `${linkBase}${translatedSlug}`;
  });

  return { path };
}
