<template>
  <div class="app sticky-footer">
    <div class="wrapper">
      <SiteHeader />
      <NuxtPage />
    </div>
    <SiteFooter :key="locale" class="footer" />
    <CookieConsent />
  </div>
</template>

<script setup lang="ts">
import CookieConsent from '~/components/content-modules/CookieConsent.vue';

const { locale } = useI18n();

useSchemaOrg([
  defineLocalBusiness({
    name: 'Freies Gymnasium Zürich',
    address: {
      streetAddress: 'Arbenzstrasse 19',
      addressLocality: 'Zürich',
      addressRegion: 'ZH',
      postalCode: '8008',
      addressCountry: 'CH',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 47.3596434,
      longitude: 8.5551857,
    },
    url: 'https://fgz.ch',
    telephone: '043 456 77 77',
    email: 'sekretariat@fgz.ch',
  }),
]);
</script>

<style lang="scss">
main:not(:has(.header-media)):not(:has(.header-text)) {
  margin-top: var(--spacing-3xl);
}

.sticky-footer {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 1fr auto;
  // ℹ️ We need the following line due to overflowing problems when child content is too wide
  grid-template-columns: minmax(0, auto);

  > .wrapper {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }

  > .footer {
    grid-row: 2 / -1;
    grid-column: 1 / -1;
  }
}
</style>
