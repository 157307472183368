import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93P00Y0RKeHbMeta } from "/opt/render/project/src/pages/[...slug].vue?macro=true";
import { default as _91slug_93aojXXFJ8bPMeta } from "/opt/render/project/src/pages/news/[slug].vue?macro=true";
import { default as component_45stubzFO8P7pzWFMeta } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzFO8P7pzWF } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    component: () => import("/opt/render/project/src/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/opt/render/project/src/pages/[...slug].vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "news-slug___de",
    path: "/de/news/:slug()",
    component: () => import("/opt/render/project/src/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/opt/render/project/src/pages/news/[slug].vue")
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/newsnewsletter/news",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/alumni",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/alumni/alumni-mitgliederbereich",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/anmeldung",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/anmeldung-schulnetz",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/aufnahme-uebertrittsbedingungen",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/aufnahme-uebertrittsbedingungen/reglemente",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/aufnahme-uebertrittsbedingungen/termine",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/bildungsangebot/infoanlaesse",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/aufnahme-uebertrittsbedingungen/kosten",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/bildungsangebot",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/bildungsangebot/uebersicht",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/bildungsangebot/einsprachige-ausbildung",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/weitere-angebote",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/weitere-angebote/aufgabenbetreuung-und-einzelfoerderung",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/weitere-angebote/musik",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/weitere-angebote/sport",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/weitere-angebote/thaf",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/weitere-angebote/verpflegung-betreuung-ueber-mittag",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/bildungsangebot/zweisprachige-ausbildung",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/standort/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/service/kontakt",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/standort",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/stellenangebot",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/portraet/ueber-uns",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/team/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/portraet",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/portraet/ueber-uns",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/portraet/unser-leitbild",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/team",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/bildende-kunst",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/biologie",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/chemie",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/deutsch",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/englisch",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/geographie",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/geschichte-staatskunde",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/informatik",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/latein-griechisch",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/mathematik",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/musik",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/physik",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/religion-philosophie",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/romanistik",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/sport",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/vorbereitungsklassen",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/fachschaften/wirtschaft-recht",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/portraet/geschichte",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/lehrerschaft",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/ueber-das-fgz/traegerschaft",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/portrait/about-us",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/team",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/subject-departments/chemistry",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/subject-departments/geography",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/subject-departments/mathematics",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/subject-departments/physics",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/portrait/history",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/governing-body",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/teachers",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/for-interested-parties/conditions-for-transferregulations/regulations",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/for-interested-parties/education-programmes/information-events",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/for-interested-parties/conditions-for-transferregulations/feescosts",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/for-interested-parties",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/for-interested-parties/education-programmes",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/for-interested-parties/education-programmes/overview",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de/fuer-interessierte/weitere-angebote/student-exchange-programmes",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/for-interested-parties/education-programmes/bilingual-education-programme",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/for-interested-parties/education-programmes/monolingual-education-programme",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/for-interested-parties/registration",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/registration-schulnetz",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en/about-fgz/vacant-positions",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de-CH-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  }
]