<template>
  <nav class="lang-switch copy-small" :aria-label="messages[locale].ariaLabelNavigation">
    <p class="_visually-hidden">
      {{ messages[locale].infoCurrentLanguage }}
    </p>
    <ul :class="['list', { '-dropdown': !inFooter && canSwitchLocale }]" :aria-label="messages[locale].ariaLabelList">
      <li v-for="loc in availableLocales" :key="`${String(loc)}-${String(localisedRoutes[loc])}`" class="item">
        <NuxtLink
          v-if="localisedRoutes[loc]"
          :lang="String(loc)"
          :hreflang="String(loc)"
          :title="messages[loc].showPageInLang"
          class="link"
          :class="{ '-active': loc === locale }"
          :to="localisedRoutes[loc]"
        >
          {{ loc }}
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { type CustomLinkDefinition, useInternalRoute } from '~/datocms/useInternalRoute';

defineProps({
  inFooter: {
    type: Boolean,
    default: false,
  },
});

type Locale = 'de' | 'en';

const { locale } = useI18n();

const availableLocales: Locale[] = ['de', 'en'];

const route = useRoute();
const localisedRoutes = ref<Record<Locale, string | null>>({
  de: null,
  en: null,
});

watch(
  () => route.meta,
  function () {
    // todo add type to RouteMeta
    // @ts-ignore
    setTranslatedPaths(route.meta.pageLink);
  },
  {
    deep: true,
  },
);

onMounted(function () {
  // todo add type to RouteMeta
  // @ts-ignore
  setTranslatedPaths(route.meta.pageLink);
});

function setTranslatedPaths(linkDefinition: CustomLinkDefinition | undefined) {
  const { path: pathDE } = useInternalRoute(linkDefinition, 'de');
  localisedRoutes.value.de = pathDE.value;

  const { path: pathEN } = useInternalRoute(linkDefinition, 'en');
  localisedRoutes.value.en = pathEN.value;
}

const canSwitchLocale = computed(() => {
  return Object.values(localisedRoutes.value).filter((l) => l).length > 1;
});

const messages: Record<string, Record<string, string>> = {
  en: {
    ariaLabelNavigation: 'Language navigation',
    ariaLabelList: 'Switch language',
    infoCurrentLanguage: `Language navigation. The current language is english`,
    showPageInLang: 'View this page in english',
  },
  de: {
    ariaLabelNavigation: 'Sprachnavigation',
    ariaLabelList: 'Sprache wechseln',
    infoCurrentLanguage: `Sprachnavigation. Die aktuelle Sprache ist deutsch.`,
    showPageInLang: 'Diese Seite auf Deutsch anzeigen',
  },
};
</script>

<style scoped lang="scss">
.lang-switch {
  display: flex;
  align-items: center;
  position: relative;
  width: 36px;
  height: 36px;

  @media (--xs), (--sm) {
    margin-bottom: 0.87rem;
  }

  &.-footer .link {
    background-color: var(--base-color-background) !important;
    order: unset;
  }

  > .list {
    display: flex;
    gap: var(--spacing-3xs);
    text-transform: capitalize;

    &.-dropdown {
      @media (--md), (--lg) {
        flex-direction: column;
        border-radius: 1.125rem;
        gap: unset;
        position: absolute;
        inset: 0;

        &:hover {
          height: 72px;
          width: 36px;
          background: var(--color-main-blue-light);
        }

        > .item > .link:not(.-link-exact-active) {
          display: none;
          justify-content: center;
        }

        &:hover > .item > .link:not(.-link-exact-active) {
          display: inline-flex;
        }

        > .item:has(.link.-link-exact-active) {
          order: 1; // active lang will be placed first
        }
      }
    }

    > .item {
      width: 36px;
      height: 36px;
      order: 2; // default order

      > .link {
        border-radius: 50%;
        display: grid;
        place-items: center;
        width: 36px;
        height: 36px;

        @media (--xs), (--sm) {
          background: var(--base-color-background);
        }

        @media (--md), (--lg) {
          background: var(--color-main-blue-light);
        }

        &.-link-exact-active {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
