<template>
  <nav id="main-navigation" class="main-navigation" :aria-label="messages[locale].ariaLabelMainNavigation">
    <ul v-if="navLinks.length" class="list -first-level">
      <li v-for="item in navLinks" :key="`nav-link-${item.id}`" class="item text-md-lg text-md-md">
        <NavLink class="navlink -first-level" :data="item">
          <SvgArrowDown v-if="item.subtree.length" />
        </NavLink>
        <ul v-if="item.subtree && item.subtree.length" class="list -second-level">
          <li v-for="it in item.subtree" :key="it.id" class="item text-lg-sub-navlink">
            <NavLink class="navlink" :data="it" />
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { Locale } from '@intlify/core-base';
import type { GetMainNavigationQuery } from '#gql';
import NavLink from '~/components/site-header/NavLink.vue';
import SvgArrowDown from '~/components/site-header/svgs/SvgArrowDown.vue';

const { localeProperties, locale } = useI18n();

const { data } = await useFetch<GetMainNavigationQuery>('/api/content/main-navigation', {
  query: {
    locale: localeProperties.value.siteLocale,
  },
});

const mainNavigation = computed(function () {
  return (data.value as GetMainNavigationQuery).mainNavigation;
});

const navLinks = computed(function () {
  if (mainNavigation.value) {
    return mainNavigation.value.navLinks ?? [];
  }

  return [];
});

const messages: Record<Locale, Record<string, string>> = {
  en: {
    ariaLabelMainNavigation: 'Main navigation',
  },
  de: {
    ariaLabelMainNavigation: 'Hauptnavigation',
  },
};
</script>

<style scoped lang="scss">
.main-navigation {
  position: relative;

  > .list {
    display: flex;

    @media (--md) {
      gap: var(--spacing-3xs);
    }

    @media (--lg) {
      gap: var(--spacing-2xs);
    }
  }
}

.list {
  > .item {
    > .navlink {
      &.-link-exact-active,
        // first-level nav link is also bold if an associated second-level nav link is active
      &.-first-level:has(+ .list.-second-level .navlink.-link-exact-active) {
        font-weight: bold;
      }

      &.-first-level {
        display: flex;
        align-items: center;
        white-space: nowrap;
        position: relative;
      }

      + .list.-second-level {
        @media (--md), (--lg) {
          display: none;
          position: absolute;
          left: 0;
          top: 100%;
          background-color: var(--base-color-background);
          gap: var(--spacing-3xs);
          flex-wrap: wrap;
          flex-direction: column;
          max-height: 16rem;
          width: 100%;
          padding: 2.47rem 0 2.5rem;

          &::before {
            position: absolute;
            content: '';
            width: 200vw;
            background: var(--base-color-background);
            height: 15rem;
            inset: 0;
            transform: translateX(-50%);
            z-index: -1;
            box-shadow: 5px 10px 25px -10px rgba(39, 39, 39, 0.1);
          }

          &:hover {
            display: flex;
          }

          > .item {
            width: 21.25rem;
            margin-right: var(--spacing-sm);

            > .nav-link {
              display: inline;
            }

            > .nav-link:not(.-link-exact-active):hover {
              border-bottom: 1px solid var(--base-color-brand);
              transition: unset;
            }
          }
        }
      }

      &:hover + .list.-second-level {
        @media (--md), (--lg) {
          display: flex;
        }
      }
    }
  }
}
</style>
