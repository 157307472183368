import routerOptions0 from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  linkActiveClass: "-link-active",
  linkExactActiveClass: "-link-exact-active",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
}