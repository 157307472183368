<template>
  <button class="hamburger-menu" aria-label="toggle navigation">
    <span class="line"></span>
    <span class="line"></span>
  </button>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.hamburger-menu {
  cursor: pointer;
  position: relative;
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  background: none;
  border: none;

  &.-open {
    > .line {
      &:nth-child(1) {
        transform: translateY(0) rotate(45deg);
      }

      &:nth-child(2) {
        transform: translateY(0) rotate(-45deg);
      }
    }
  }

  > .line {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: currentColor;
    transition: transform 250ms ease-out;

    &:nth-child(1) {
      transform: translateY(-0.3rem);
    }

    &:nth-child(2) {
      transform: translateY(0.3rem);
    }
  }
}

.screenreader-only {
  @include hide-visually();
}
</style>
