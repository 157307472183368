<template>
  <svg
    class="icon-arrow-down"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path d="M10.5 4.25L5.99998 8.75L1.5 4.25" stroke="#2350AC" stroke-width="2" stroke-miterlimit="10" />
  </svg>
</template>
