<template>
  <div
    v-if="isReady && !isAccepted"
    class="cookie-consent"
    role="dialog"
    aria-labelledby="cookie-consent-title"
    aria-describedby="cookie-consent-description"
  >
    <div class="inner">
      <div class="content">
        <h2 id="cookie-consent-title" class="copy-small-bold title">{{ t('title') }}</h2>
        <p id="cookie-consent-description" class="copy-small text">
          {{ t('text') }}
          <a :href="t('linkUrl')" class="button-link -secondary link">{{ t('linkText') }}</a
          >.
        </p>
      </div>
      <button class="button-secondary close" @click="acceptCookies()">{{ t('closeText') }}</button>
    </div>
  </div>
</template>

<i18n lang="yaml">
de:
  title: Wir verwenden Cookies
  text: Wir nutzen spezielle Funktionen wie Cookies, um wesentliche Dienste und Funktionen unserer Website zu ermöglichen und Daten darüber zu sammeln, wie Besucher mit unserer Website, unseren Produkten und Diensten interagieren. Wie wir Cookies nutzen und Personendaten bearbeiten, erfahren Sie in unserer
  linkText: Datenschutzerklärung
  linkUrl: /de/datenschutz
  closeText: Schliessen
en:
  title: We use cookies
  text: We use special features like cookies to enable essential services and functions on our website and to collect data on how visitors interact with our website, products, and services. You can find out how we use cookies and process personal data in our
  linkText: Privacy Policy
  linkUrl: /en/data-protection
  closeText: Close
</i18n>

<script setup lang="ts">
import { useStorage } from '@vueuse/core';

const { t } = useI18n();

const isReady = ref(false);
const isAccepted = useStorage('cookie-consent', false);

function acceptCookies() {
  isAccepted.value = true;
}

onMounted(() => {
  isReady.value = true;
});
</script>

<style scoped lang="scss">
.cookie-consent {
  position: fixed;
  z-index: var(--z-overlay);
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
  color: var(--base-color-brand);
  width: 100%;
  box-shadow: 5px 10px 25px 0 rgba(39, 39, 39, 0.1);
  hyphens: none;

  > .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: var(--site-max-width);
    margin: auto;

    @media (--xs), (--sm) {
      flex-direction: column;
      gap: 1.38rem;
      padding: var(--spacing-2xs);
    }

    @media (--md) {
      padding: var(--spacing-xs) var(--spacing-2xs);
    }

    @media (--md), (--lg) {
      gap: var(--spacing-md);
    }

    @media (--lg) {
      padding: var(--spacing-xs) var(--spacing-2xl);
    }

    > .content {
      width: 100%;

      @media (--md), (--lg) {
        max-width: 54rem;
      }

      > .title {
        @media (--xs), (--sm) {
          margin-bottom: var(--spacing-3xs);
        }

        @media (--md), (--lg) {
          margin-bottom: var(--spacing-4xs);
        }
      }

      :deep(.link) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    > .close {
      width: 100%;

      @media (--md), (--lg) {
        width: 14.19rem;
      }
    }
  }
}
</style>
