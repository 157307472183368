<template>
  <NuxtLink class="fgz-home-link" :to="localePath('/')">
    <span class="screenreader-only">Freies Gymnasium Zürich - zur Startseite</span>
    <img v-if="header?.logo?.url" class="logo -blue" :src="header?.logo?.url" :alt="header.logo.alt as string" />
    <img v-if="header?.logoAlternative?.url" class="logo -white" :src="header.logoAlternative.url" :alt="header.logoAlternative.alt as string" />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { GetHeaderQuery } from '#gql';

const localePath = useLocalePath();
const { localeProperties, } = useI18n();

const { data } = await useFetch<GetHeaderQuery>('/api/content/header', {
  query: {
    locale: localeProperties.value.siteLocale,
  },
});

const header = data.value?.header;
</script>

<style scoped lang="scss">
.screenreader-only {
  @include hide-visually;
}

.fgz-home-link {
  margin-right: auto;

  > .logo {
    @media (--xs), (--sm) {
      height: 2.5rem;
    }

    @media (--md) {
      height: 3rem;
    }

    @media (--lg) {
      height: 4.5rem;
    }

    &.-white{
      display: none;
    }
  }
}
</style>
