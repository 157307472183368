<template>
  <div class="app sticky-footer">
    <div class="wrapper">
      <SiteHeader />
      <main class="error-page">
        <header class="header-text">
          <div v-if="error?.statusCode === 404" class="error404 layout">
            <h1 class="title">
              <span class="line1 text-4xl">{{ t('titleLine1') }}</span
              ><br />
              <span class="line2 text-4xl-display">{{ t('titleLine2') }}</span>
            </h1>
            <p class="text text-lg">{{ t('text') }}</p>
            <a href="/" class="button-secondary">{{ t('backToHome') }}</a>
          </div>
          <div v-else-if="error">
            <pre>{{ error }}</pre>
            <button @click="handleError()">Clear error</button>
          </div>
        </header>
        <div class="backgroundwrapper">
          <img
            src="/bg/bg_large.svg"
            alt="multiple stair shapes on a background"
            aria-hidden="true"
            class="error-background -large"
          />
          <img
            src="/bg/bg_small.svg"
            alt="multiple stair shapes on a background"
            aria-hidden="true"
            class="error-background -small"
          />
        </div>
      </main>
    </div>
    <SiteFooter class="footer" />
  </div>
</template>

<i18n lang="yaml">
de:
  titleLine1: Hast du dich
  titleLine2: verlaufen?
  text: Fange nochmals auf der Startseite an.
  backToHome: Zur Startseite
en:
  titleLine1: Did you get
  titleLine2: lost?
  text: Start again on the home page.
  backToHome: Back to home
</i18n>

<script setup lang="ts">
const error = useError();
const { t, locale } = useI18n();

function handleError() {
  clearError({ redirect: `/${locale.value}` });
}

useHead({
  title:
    locale.value === 'de'
      ? '404 - Seite nicht gefunden | Freies Gymnasium Zürich'
      : '404 - Page not found | Freies Gymnasium Zürich',
});
</script>

<style lang="scss" scoped>
.error-page {
  width: 100%;

  > .header-text {
    @include grid-layout();

    & {
      padding: var(--site-inner-padding);
      width: 100%;
      max-width: var(--site-max-width);
      margin: auto;

      @media (--xs), (--sm) {
        margin-top: 5.5rem;
      }

      @media (--md), (--lg) {
        margin-top: 9.5rem;
      }
    }

    .layout {
      display: grid;
      gap: var(--spacing-xs);
      justify-items: start;
      padding: var(--spacing-4xl) 0;
      max-width: 900px;

      @media (--xs), (--sm) {
        padding: var(--spacing-sm) 0 var(--spacing-2xl) 0;
      }

      @media (--xs) {
        gap: var(--spacing-2xs);
      }

      @media (--xs) {
        grid-column: 1 / -1;
      }

      @media (--sm) {
        grid-column: 2 / 24;
      }

      @media (--md), (--lg) {
        grid-column: 5 / 21;
      }
    }
  }

  > .backgroundwrapper {
    position: relative;
    width: 100%;
    background-color: var(--color-main-blue-light);
    margin-bottom: calc(var(--spacing-xs) * -1); // subtract margin-top from footer

    .error-background {
      margin: auto;

      &.-large {
        @media (--xs) {
          display: none;
        }

        @media (--sm), (--md), (--lg) {
          display: block;
        }
      }

      &.-small {
        @media (--xs) {
          display: block;
          width: 100%;
        }

        @media (--sm), (--md), (--lg) {
          display: none;
        }
      }
    }
  }
}

.sticky-footer {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 1fr auto;
  // ℹ️ We need the following line due to overflowing problems when child content is too wide
  grid-template-columns: minmax(0, auto);

  > .wrapper {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }

  > .footer {
    grid-row: 2 / -1;
    grid-column: 1 / -1;
  }
}
</style>
