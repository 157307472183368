<template>
  <a
    v-if="link && link.linkType === 'external'"
    :class="['link', iconPositionClasses]"
    :href="link.externalUrl"
    :target="link.openLinkInNewWindow ? '_blank' : '_self'"
  >
    <span v-if="iconPosition === 'left'" class="iconwrapper">
      <SvgLinkBold />
    </span>
    {{ link.linkText }}
    <slot />
    <span v-if="iconPosition === 'right'" class="iconwrapper">
      <SvgLink />
    </span>
  </a>
  <a
    v-else-if="link && link.linkType === 'email'"
    :class="['link', iconPositionClasses]"
    :href="`mailto:${link.emailUrl}`"
    :target="'_blank'"
  >
    <span v-if="iconPosition === 'left'" class="iconwrapper">
      <SvgLinkBold />
    </span>
    {{ link.linkText }}
    <slot />
    <span v-if="iconPosition === 'right'" class="iconwrapper">
      <SvgLink />
    </span>
  </a>
  <NuxtLink
    v-else-if="link && link.linkType === 'internal' && internalRoute.path"
    :class="['link', iconPositionClasses]"
    :to="internalRoute.path"
    :target="link.openLinkInNewWindow ? '_blank' : '_self'"
  >
    <span v-if="iconPosition === 'left'" class="iconwrapper">
      <SvgLinkBold />
    </span>
    {{ link.linkText }}
    <slot />
    <span v-if="iconPosition === 'right'" class="iconwrapper">
      <SvgLink />
    </span>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { LinkFragment } from '#gql';
import { useInternalRoute } from '~/datocms/useInternalRoute';
import SvgLink from '~/components/content-modules/svgs/SvgLink.vue';
import SvgLinkBold from '~/components/content-modules/svgs/SvgLinkBold.vue';

const props = defineProps<{
  link?: LinkFragment;
  iconPosition?: 'left' | 'right';
}>();

const { locale } = useI18n();

const internalRoute = useInternalRoute(props.link?.internalUrl, locale.value as 'de' | 'en');

const iconPositionClasses = computed(function () {
  return {
    '-icon-left': props.iconPosition === 'left',
    '-icon-right': props.iconPosition === 'right',
  };
});
</script>

<style scoped lang="scss"></style>
