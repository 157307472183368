<template>
  <nav id="main-navigation" class="main-navigation-small" :aria-label="messages[locale].ariaLabelMainNavigation">
    <ul v-if="navLinks.length" class="list -first-level">
      <li
        v-for="item in navLinks"
        :key="`nav-link-${item.id}`"
        class="item text-lg-sub-navlink"
        :class="{ '-hidden': activeNavLink && activeNavLink.id !== item.id }"
      >
        <div class="group" :class="{ '-hidden': activeNavLink }">
          <NavLink class="navlink -first-level" :data="item" />
          <SvgArrowRight v-if="item.subtree.length" class="arrow" @click="setActiveNavLink(item)" />
        </div>
        <ul
          v-if="item.subtree && item.subtree.length"
          class="list -second-level"
          :class="{ '-visible': activeNavLink?.id === item.id }"
        >
          <li class="item -back" @click="setActiveNavLink(null)">
            <SvgArrowLeft />
            <button class="text-xl-xs text-xl-sm button">{{ t('backText') }}</button>
          </li>
          <li v-for="it in item.subtree" :key="it.id" class="item text-lg-sub-navlink">
            <NavLink class="navlink" :data="it" />
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { Locale } from '@intlify/core-base';
import type { GetMainNavigationQuery, NavLinkFragment } from '#gql';
import NavLink from '~/components/site-header/NavLink.vue';
import SvgArrowLeft from '~/components/site-header/svgs/SvgArrowLeft.vue';
import SvgArrowRight from '~/components/site-header/svgs/SvgArrowRight.vue';

type MainNavigationNavLink = NavLinkFragment & { id: string; subtree: NavLinkFragment[] };

const { localeProperties, locale } = useI18n();

const route = useRoute();

const { t } = useI18n();

const { data } = await useFetch<GetMainNavigationQuery>('/api/content/main-navigation', {
  query: {
    locale: localeProperties.value.siteLocale,
  },
});

const mainNavigation = computed(function () {
  return (data.value as GetMainNavigationQuery).mainNavigation;
});

const navLinks = computed(function (): MainNavigationNavLink[] {
  if (mainNavigation.value) {
    return mainNavigation.value.navLinks ?? [];
  }

  return [];
});

const messages: Record<Locale, Record<string, string>> = {
  en: {
    ariaLabelMainNavigation: 'Main navigation',
  },
  de: {
    ariaLabelMainNavigation: 'Hauptnavigation',
  },
};

const activeNavLink = ref<MainNavigationNavLink | null>(null);

function setActiveNavLink(navLink: MainNavigationNavLink | null) {
  activeNavLink.value = navLink;
}

watch(
  () => route.path,
  function () {
    setActiveNavLink(null);
  },
);
</script>

<i18n lang="yaml">
de:
  backText: Zurück
en:
  backText: Back
</i18n>

<style scoped lang="scss">
.main-navigation-small {
  width: 100%;
  height: 100lvh;

  &:not(.-open) {
    visibility: hidden;
    pointer-events: none;
    display: none;
  }

  &.-open {
    visibility: visible;
  }

  > .list {
    display: flex;
    flex-direction: column;
  }
}

.list {
  > .item {
    .navlink {
      width: 100%;

      &.-link-exact-active,
        // first-level nav link is also bold if an associated second-level nav link is active
      &.-first-level:has(~ .list.-second-level .navlink.-link-exact-active) {
        font-weight: bold;
      }

      &.-first-level {
        &.-hidden {
          visibility: hidden;
        }
      }
    }
  }
}

.list.-first-level {
  > .item {
    &.-hidden {
      display: none;
    }

    > .group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--spacing-3xs) 0;
      border-bottom: 2px solid var(--color-main-blue-medium);

      &.-hidden {
        display: none;
      }

      > .arrow {
        cursor: pointer;
      }
    }
  }
}

.list.-second-level {
  display: none;
  height: 0;

  &.-visible {
    display: block;
    height: 100%;

    > .item {
      padding: var(--spacing-3xs) 0;
      border-bottom: 2px solid var(--color-main-blue-medium);
    }
  }

  > .item {
    &.-back {
      display: flex;
      align-items: center;
      gap: var(--spacing-4xs);
      cursor: pointer;

      > .button {
        background: transparent;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
