<template>
  <svg
    class="icon-arrow-right"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path d="M7.5 21L16.5 12L7.5 3" stroke="#2350AC" stroke-width="2" stroke-miterlimit="10" />
  </svg>
</template>
