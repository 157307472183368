<template>
  <NuxtLink v-if="path && typeName" class="nav-link" :to="path">
    {{ title }}
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { NavLinkFragment } from '#gql';
import { useInternalRoute } from '~/datocms/useInternalRoute';

const props = defineProps<{
  data: NavLinkFragment;
}>();

const { locale } = useI18n();

const { path } = useInternalRoute(props.data.link, locale.value as 'de' | 'en');

const typeName = computed(function () {
  if (props?.data?.link && '__typename' in props.data.link) {
    return props.data.link.__typename;
  }
  return '';
});

const title = computed(function () {
  if (props?.data?.link && 'title' in props.data.link) {
    return props.data.link.title;
  }
  return '';
});
</script>

<style scoped lang="scss">
.nav-link {
  display: flex;
  gap: 0.25rem;
}
</style>
